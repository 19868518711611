import React from "react";
import Layout from "../components/Layout";
import "../styles/global.css";
import "../styles/pageHeader.css";

const Presentation = () => {
  return (
    <Layout>
      <main
        style={{
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: 20,
          textAlign: "center",
        }}
      >
        <button
          style={{ fontSize: 24 }}
          onClick={() =>
            window.open(
              "https://www.dropbox.com/s/slq895285o5zhe9/PI%20Foam%20System%20with%20videos%2010.15.pptx?dl=0",
              "_blank"
            )
          }
        >
          Downloadable presentation
        </button>
      </main>
    </Layout>
  );
};

export default Presentation;
